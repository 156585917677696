.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}
.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  height: 85%;
  width: 90%;
  border: 5px double rgb(10, 110, 110);
  border-radius: 20px;
  background-color: rgb(58, 60, 61);
  margin-top: 1%;
}
.modal {
  position: absolute;
  height: 55%;
  width: 70%;
  background-color: blanchedalmond;
  opacity: .2;
}