@keyframes color {
  0% { 
    border-color: #f36cd1; 
    color: #87f4f4; 
  }
  20% { 
    border-color: #87f4f4; 
    color: #f36cd1;
  } 
  40%   { 
    border-color:#fded6f; 
    color:#f39508; 
  }
  60%  { 
    border-color:#f39508; 
    color:#fded6f; 
  }
  80%  { 
    border-color:#87f4f4; 
    color:#f36cd1; 
  }
  100% { 
    border-color:#f36cd1; 
    color:#87f4f4; 
  }
 }
 @keyframes color2 {
  0% { 
    color: #f36cd1; 
    border-color: #87f4f4; 
  }
  20% { 
    color: #87f4f4; 
    border-color: #f36cd1; 
  } 
  40%   { 
    color:#fded6f; 
    border-color:#f39508; 
  }
  60%  { 
    color:#f39508; 
    border-color:#fded6f; 
  }
  80%  { 
    color:#87f4f4; 
    border-color:#f36cd1; 
  }
  100% { 
    color:#f36cd1; 
    border-color:#87f4f4; 
  } 
}
.email {
  font-size: 1em;
  text-align: right;
  padding-right: 1%;
}

.headerRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 33%;
  color: rgb(249, 250, 251);
  text-align: center;
  font-size: 1vw;
}
.name {
  font-size: 1em;
}

.role {
  font-size: 1em;
}
.title {  
  color: rgb(225, 228, 238);
  font-size: 1.4em;
  padding: 3px;
  font-weight: 600;
  width: 33%;
  }
.main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: space-between;
  align-content: center;
  align-self: center;
  font-size: 1.4em;
  height: 5%;
  width: 100%;
}