@keyframes color {
  0% { 
    border-color: #f36cd1; 
  }
  20% { 
    border-color: #87f4f4; 
  } 
  40%   { 
    border-color:#fded6f; 
  }
  60%  { 
    border-color:#f39508; 
  }
  80%  { 
    border-color:#87f4f4; 
  }
  100% { 
    border-color:#f36cd1; 
  }
 }

.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
}

.mainRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 90%;
  margin-top: 2%;
  justify-content: flex-start;
  align-items: flex-start;
}

.body {
  width: 90%;
  height: 95%;
  font-size: 1.6em;
  color: antiquewhite;
  overflow-y: scroll;
  -ms-overflow-style: none; 
  scrollbar-width: none; 
  border: 2px solid #10175e;
  box-shadow: 1px 2px 9px antiquewhite;
  border-radius: 10px;
  padding: 2%;
  background-color: antiquewhite;

}
.body::-webkit-scrollbar {
  display: none;
}
.navLink {
  color: antiquewhite;
  -webkit-text-stroke: .1px #10175e;
  text-shadow: 1px 2px 9px #1c93e2;
  font-size: 1em;
  font-weight: 600;
  padding: 5%;
  text-align: center;
  margin-left: 5%;
  border: .5px solid #1c93e2;
  border-radius: 5px;
  margin-bottom: 3%;
  animation: color 7s infinite;
}
.navLink:hover {
  cursor: pointer;
}

.inactiveNavLink {
  color: antiquewhite;
  -webkit-text-stroke: .1px #10175e;
  text-shadow: 1px 2px 9px #1c93e2;
  font-size: 1em;
  font-weight: 600;
  padding: 5%;
  text-align: center;
  margin-left: 5%;
  border: .5px solid #1c93e2;
  border-radius: 5px;
  pointer-events: none;
  margin-bottom: 3%;

}
.inactiveNavLink:hover {
  cursor: default;
}

.leftNav {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 7.5%;
}

.postContainer {
  height: 90%;
  width: 85%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 5%;
}

.topRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  height: 5%;
  margin-bottom: 1%;
}

.date {
  font-size: 2em;
  font-weight: 600;
  color: rgb(225, 228, 238);
  text-shadow: 1px 1px 9px #1c93e2;
  -webkit-text-stroke: 1px #10175e;
}

.title {
  font-size: 4em;
  text-align: left;
  font-weight: bolder;
  color: rgb(225, 228, 238);
  text-shadow: 1px 2px 9px #1c93e2;
  -webkit-text-stroke: 2px #10175e;
}
.postLine {
  color: #10175e;

}