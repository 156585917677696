@keyframes color {
  0% { 
    box-shadow: 0 0 15px #f36cd1; 
    -moz-box-shadow: 0 0 15px #f36cd1; 
    -webkit-box-shadow: 0 0 15px #f36cd1; 
    -o-box-shadow: 0 0 15px #f36cd1; 
  }
  20% { 
    box-shadow: 0 0 15px #87f4f4; 
    -moz-box-shadow: 0 0 15px #87f4f4; 
    -webkit-box-shadow: 0 0 15px #87f4f4; 
    -o-box-shadow: 0 0 15px #87f4f4; 
  } 
  40%   { 
    box-shadow: 0 0 15px #fded6f; 
    -moz-box-shadow: 0 0 15px #fded6f; 
    -webkit-box-shadow: 0 0 15px #fded6f; 
    -o-box-shadow: 0 0 15px #fded6f; 
  }
  60%  { 
    box-shadow: 0 0 15px #f39508; 
    -moz-box-shadow: 0 0 15px #f39508; 
    -webkit-box-shadow: 0 0 15px #f39508; 
    -o-box-shadow: 0 0 15px #f39508; 
  }
  80%  { 
    box-shadow: 0 0 15px #87f4f4; 
    -moz-box-shadow: 0 0 15px #87f4f4; 
    -webkit-box-shadow: 0 0 15px #87f4f4; 
    -o-box-shadow: 0 0 15px #87f4f4; 
  }
  100% { 
    box-shadow: 0 0 15px #f36cd1; 
    -moz-box-shadow: 0 0 15px #f36cd1; 
    -webkit-box-shadow: 0 0 15px #f36cd1; 
    -o-box-shadow: 0 0 15px #f36cd1; 
  }
 }

.classification {
  font-size: 2vh;
  font-weight: 100;
}
.description {
  font-size: 1.5vh;
  text-align: center;
  padding: 3px;
  /* padding-left: 5px;
  padding-right: 5px; */
}
.frameworks {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.project {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: rgb(22, 63, 101);
  height: 20%;
  width: 20%;
  background-color: rgb(225, 228, 238);
  border: 2px solid rgb(10, 110, 110);
  border-radius: 15px;
  border: solid 1px #555;
  background-color: #eed;
  box-shadow:  0 0 8px  rgb(25, 179, 179);
  -moz-box-shadow: 0 0 8px  rgb(25, 179, 179);
  -webkit-box-shadow: 0 0 8px  rgb(25, 179, 179);
  -o-box-shadow: 0 0 8px  rgb(25, 179, 179);
  padding: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.project:hover { 
  cursor: pointer;
  background-color: aliceblue;
  animation: color 9s infinite;
  box-shadow:  0 0 10px  #fff;
  -moz-box-shadow: 0 0 10px  #fff;
  -webkit-box-shadow: 0 0 10px  #fff;
  -o-box-shadow: 0 0 10px  #fff;
  /* color: #fff; */
}
.name {
  font-size: 2.5vh;
  font-weight: bold;
  /* margin-bottom: 5px; */
}