@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-40px);
  }
  80%,
  100% {
    transform: translateY(0px);
  }
}
.loading__letter {
  font-size: 10vh;
  font-weight: bold;
  letter-spacing: 4px;
  /* text-transform: uppercase; */
  animation-name: bounce;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.loading__letter:nth-child(2) {
  animation-delay: 0.1s;
}
.loading__letter:nth-child(3) {
  animation-delay: 0.2s;
}
.loading__letter:nth-child(4) {
  animation-delay: 0.3s;
}
.loading__letter:nth-child(5) {
  animation-delay: 0.4s;
}
.loading__letter:nth-child(6) {
  animation-delay: 0.5s;
}
.loading__letter:nth-child(7) {
  animation-delay: 0.6s;
}
.loading__letter:nth-child(8) {
  animation-delay: 0.8s;
}
.loading__letter:nth-child(9) {
  animation-delay: 1s;
}
.loading__letter:nth-child(10) {
  animation-delay: 1.2s;
}
.container {
  opacity: .8;
  border: 5px double rgb(10, 110, 110);
  border-radius: 20px;
  margin-top: 3vh;
  background-color: rgb(232, 234, 235);
  height: 75vh;
  width: 70vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 2vw;
  margin-right: 2vw;
  box-shadow: 1vw;
}
.eduBoxes {
  height: 75vh;
  width: 75vw;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
}
.loading {
  display: flex;
  flex-direction: row;
  color: rgb(225, 228, 238);
  font-size: 5vh;
  border-bottom: 5px solid rgb(10, 110, 110);
}
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {  
  color: rgb(225, 228, 238);
  font-size: 5vh;
  border: 10px double rgb(10, 110, 110);
  border-radius: 20px;
  padding: 1vh;
  background-color: rgb(58, 60, 61);
  font-weight: bolder;
  margin-top: 1vh;
  margin-bottom: 1vh;
}