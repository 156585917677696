@keyframes color {
  0% { 
    box-shadow: 0 0 15px #f36cd1; 
    -moz-box-shadow: 0 0 15px #f36cd1; 
    -webkit-box-shadow: 0 0 15px #f36cd1; 
    -o-box-shadow: 0 0 15px #f36cd1; 
  }
  20% { 
    box-shadow: 0 0 15px #87f4f4; 
    -moz-box-shadow: 0 0 15px #87f4f4; 
    -webkit-box-shadow: 0 0 15px #87f4f4; 
    -o-box-shadow: 0 0 15px #87f4f4; 
  } 
  40%   { 
    box-shadow: 0 0 15px #fded6f; 
    -moz-box-shadow: 0 0 15px #fded6f; 
    -webkit-box-shadow: 0 0 15px #fded6f; 
    -o-box-shadow: 0 0 15px #fded6f; 
  }
  60%  { 
    box-shadow: 0 0 15px #f39508; 
    -moz-box-shadow: 0 0 15px #f39508; 
    -webkit-box-shadow: 0 0 15px #f39508; 
    -o-box-shadow: 0 0 15px #f39508; 
  }
  80%  { 
    box-shadow: 0 0 15px #87f4f4; 
    -moz-box-shadow: 0 0 15px #87f4f4; 
    -webkit-box-shadow: 0 0 15px #87f4f4; 
    -o-box-shadow: 0 0 15px #87f4f4; 
  }
  100% { 
    box-shadow: 0 0 15px #f36cd1; 
    -moz-box-shadow: 0 0 15px #f36cd1; 
    -webkit-box-shadow: 0 0 15px #f36cd1; 
    -o-box-shadow: 0 0 15px #f36cd1; 
  }
 }

.activeArrow {
  color: #fff;
  font-size: 2em;
  border: 1px solid #f36cd1;
  border-radius: 10px;
  padding: 2%;
  border-color: #f36cd1;
  animation: color 7s infinite;
  width: fit-content;
  height: fit-content;
}
.activeArrow:hover {
  cursor: pointer;
  border: 5px solid #f36cd1;
  animation: color 3s infinite;
  font-weight: bold;
}
.inactiveArrow {
  color: rgb(183, 176, 176);
  font-size: 2em;
  border: 1px solid #f36cd1;
  border-radius: 10px;
  padding: 2%;
  border-color: #7c7f80;
  pointer-events: none;
}
.topSection {
  text-align: center;
  width: 75%;
  height: 50%;
}
.bottomSection {
  display: flex;
  flex-direction: row;
  width: 75%;
  height: 50%;
}
.body {
  background-color: rgb(250, 252, 253);
  text-align: left;
  border: 5px double rgb(32, 55, 123);
  border-radius: 15px;
  padding: 1%;
  margin: 1%;
  opacity: .8;
  height: fit-content;
  max-height: 55%;
  width: 100%;
  font-size: 1.2em;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none; 
}
.body::-webkit-scrollbar {
  display: none;
}
.leftColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  height: 100%;
}
.rightColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 50%;
  height: 100%;
}
.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  position: relative; 
}
.aboutHeader {
  font-size: 4em;
  text-align: center;
  margin-left: 2%;
  font-weight: bolder;
  position: relative;
  color: #1c93e2;
  -webkit-text-stroke: 1px rgb(225, 228, 238);
  text-shadow: 1px 2px 9px #1c93e2;
}
.hobbyHeader {
  font-size: 4em;
  text-align: left;
  margin-left: 2%;
  font-weight: bolder;
  position: relative;
  color: rgb(225, 228, 238);
  text-shadow: 1px 2px 9px #1c93e2;

  -webkit-text-stroke: 2px #10175e;
}
.hobby {
  font-size: 3em;
  font-weight: bold;
  text-align: left;
  color: #1c93e2;
  -webkit-text-stroke: 1px rgb(225, 228, 238);
  text-shadow: 1px 2px 9px #1c93e2;
}
.hobbyImage {
  height: 20vw;
  max-height: 30vh;
  width: 20vw;
  max-width: 30vh;
  border-radius: 20px;
  opacity: .8;
  /* object-fit: cover; */
}
.hobbies {
  display: flex;
  flex-direction: row;
  position: relative;
}
.images {
  /* display: flex;
  flex-direction: row; */
  flex-wrap: inherit;
  height: 90%;
}
.inactiveHobbyImage {
  height: 25vh;
  width: 30vh;
  border-radius: 20px;
  position: absolute;
  visibility: hidden;
}
.inactiveVideo {
  height: 75vh;
  width: 98vh;
  object-fit: cover;
  position: absolute;
  border-radius: 15px;
  opacity: 0;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.nextButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  align-self: flex-end;
  width: 100%;
  margin-bottom: 1%;
  margin-top: 5%;
  min-height: 20%;
  max-height: 30%;
}
.overlay {
  height: 45%;
  min-height: 85%;
  width: 60%;
  object-fit: cover;
  position: absolute;
  border-radius: 15px;
  background-color: rgba(7, 22, 65, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 2vw;
}
.title {  
  color: rgb(225, 228, 238);
  font-size: 5vh;
  border: 10px double rgb(10, 110, 110);
  border-radius: 20px;
  padding: 1vh;
  background-color: rgb(58, 60, 61);
  font-weight: bolder;
  margin-top: 1vh;
  margin-bottom: 1vh; 
}
.vidContainer {
  border: .1px double rgb(10, 110, 110);
  border-radius: 20px;
  background-color: rgb(224, 240, 253);
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}
.video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  border-radius: 15px;
  
}