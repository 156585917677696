.active {
  font-size: 1vw;
  font-weight: bold;
  color: rgb(187, 179, 171);
}
.main {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: space-around;
  width: 33%;
}
.navButton {
  font-size:  1vw;
  font-weight: bold;
  color: rgb(241, 236, 227);
}
.navButton {
  cursor: pointer;
}