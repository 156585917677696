.box {
  width: 68vw;
  height: 35vh;
  /* background-color: rgb(224, 249, 241); */
  border-radius: 15px;
  padding: 1vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  
}

.column {
  text-align: left;
}

.degree {
  color: rgb(32, 55, 123);
  font-weight: bold;
  margin-bottom: 1vh;
  font-size: 2vw;
}

.graduation {
  color: rgb(32, 55, 123);
  font-size: 1.5vw;
  font-weight: bold;
  color: rgb(68, 94, 173);
}

.location {
  color: rgb(68, 94, 173);
  font-weight: bold;
  margin-bottom: 1vh;
  font-size: 1.5vw;
}

.logo {
  height: 30vh;
  border-radius: 20px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-right: 2vw;

}
/* .major{
  color: rgb(32, 55, 123);
  font-weight: bold;
  font-size: 2.5vh;

} */
.name {
  color: rgb(32, 55, 123);
  font-weight: bold;
  font-size: 2.5vw;
  margin-bottom: 1vh;
  margin-top: 1vh;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}